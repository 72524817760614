<template lang="pug">
  .c-collapsible
    .c-collapsible__detail(
      ref="content"
      :class="shown ? '-shown' : ''"
      :style="contentStyle"
    )
      slot(name="content")
    .c-collapsible__toggle(v-if="showToggleButton" @click="toggleShown" role="button")
      span {{ shown ? 'Sembunyikan' : 'Selengkapnya' }}
      i.c-icon(:class="shown ? 'c-icon--chevron-up' : 'c-icon--chevron-down'")
</template>

<script>
export default {
  name: 'BlCollapsibleDetail',

  props: {
    maxHeight: {
      type: Number,
      default: 200,
    },
  },

  data() {
    return {
      shown: false,
      showToggleButton: false,
    };
  },

  computed: {
    contentStyle() {
      return {
        maxHeight: !this.shown && this.showToggleButton ? `${this.maxHeight}px` : '100%',
      };
    },
  },

  mounted() {
    if (this.$refs.content.clientHeight > this.maxHeight) {
      this.showToggleButton = true;
    }
  },

  methods: {
    toggleShown() {
      this.shown = !this.shown;
    },
  },
};
</script>

<style lang="scss" src="./BlCollapsibleDetailMv.scss"></style>
